export const environment = {
  production: false,
  here: {
    apikey: 'XckJG5hx7XfW__OoHOjmcYIt-yjra2QAmS49hv-xy3Y', //TODO: Rimuovere chiave Mapbox prima di rilasciare
  },
  backend: {
    baseURL: 'https://entp.fcl-02.intg.fcagcv.com/',
    baseURLToken: 'https://identity.fcl-02.intg.fcagcv.com/',
    tokenRefresh: 'Basic M3A0ZDBmbWtqcjdyaG1qM2dlbm43NmQ5bWw6aTJnODZpbmdncGExaXVkc2hsY2VnMzR2MDhlMGQ0dm05azNnNTdwM3Btb3V2MmRzNTU2',
    xsrfToken: 'XSRF-TOKEN=e1a0ed75-f89d-45d3-a9aa-724d18c048ff',  
    apikey: 'VyogZVzrgs7MYojPkEYhKauRiYRwa3w63nPdgkJI',
    token: ''
    // baseURL: 'https://telematics.fcl-02.intg.intra.fcagcv.com/', // old
    // baseURLToken: 'https://identity.fcl-02.intg.intra.fcagcv.com/', //old
    // tokenRefresh: 'Basic M3A0ZDBmbWtqcjdyaG1qM2dlbm43NmQ5bWw6aTJnODZpbmdncGExaXVkc2hsY2VnMzR2MDhlMGQ0dm05azNnNTdwM3Btb3V2MmRzNTU2',
    // xsrfToken: 'XSRF-TOKEN=3ceabf7a-3d0e-4a7d-84d3-788e1f0e4583',  
    // apikey: 'VyogZVzrgs7MYojPkEYhKauRiYRwa3w63nPdgkJI',
    // token: ''
  },
  login: {
    adfsUrl_production: 'https://fedgsdp01-dev.fcagroup.com', //'https://fed04.fcagroup.com';
    adfsUrl_certification: 'https://fedgsdp01-dev.fcagroup.com', //'https://fed04.fcagroup.com';
    application_redirect_url: 'https://autodrive-roadclearance.fcl-02.intg.fcagcv.com/loginCallback', //'https://autodrive-roadclearance.fcl-02.intg.fcagcv.com/loginCallback'
    client_id: 'c9957400-3b1b-4434-887a-66682bf17bad', 
    resource_id: 'RC_NAFTA_INT',
    client_secret: '',
    userInfo: 'https://gap-ext.fcl-01.intg.fcagcv.com/usersSearch', //temptative one
  },
  apiPrefix: 'https://virtserver.swaggerhub.com/VSTOLA/RoadClearance/1.0.0',
  pushNotificationsPublicKey: "BBICiCYR0x7w1EnmqU0VbqkGhMcJv2Sd9ZKCs_A_A2PvJyFBRO2E4sHoDCVLcN4HBU4FEv3L61O4dCC6j0Jaayc", //use in the webapp; generated using the web-push generate-vapid-keys command in the terminal
  pushNotificationsPrivateKey: "dHal-TSI7X3cMfZ_caHX4EbWAaog3SLTQiRVaiMTG5A", //use in the server to identify the webapp; generated using the web-push generate-vapid-keys command in the terminal
};