/*
* Login Page
*
* This component display the login page
*/


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  loginFormStatus!: boolean;
  showPassword: boolean = false;
  unauthorizedWarning: boolean = false; 

  constructor(private router: Router, private authService:AuthService) {}

  ngOnInit() {
    environment.login.resource_id != "RC_EMEA_INT" ? this.authService.startAuthProcess() : ()=>{};
    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    //uncomment when GAP ready
    //this.authService.startAuthProcess(this.loginForm.get("username")?.value, this.loginForm.get("password")?.value);
    
    if(this.loginForm.get('username')?.value.toUpperCase() == "GAP") {
      this.authService.startAuthProcess();
    } else if (this.loginForm.get('username')?.value.toUpperCase() == "APPROVER") {
      this.authService.setDeveloperMode("approver");
    } else if (this.loginForm.get('username')?.value.toUpperCase() == "CARTOGRAPHER") {
      this.authService.setDeveloperMode("cartographer");
    } else if (this.loginForm.get('username')?.value.toUpperCase() == "ADMIN") {
      this.authService.setDeveloperMode("admin");
    } else {
      this.unauthorizedWarning = true;
      setTimeout(() => {
        this.unauthorizedWarning = false;
      }, 3000);
    }
  }

  togglePwd() {
    this.showPassword = !this.showPassword;
  }
}
