/*
 * Authentication Service
 *
 * This service manage all the interaction with the User Token
 */

import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse,} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {EventUtilityService} from '../event-utility.service';
import {PermissionService} from "../permission.service";
import {TPermission, TRole} from "../../types/perimission.type";
import {jwtDecode} from "jwt-decode";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  developerRole: TRole = "rc-cartographer";
  developerRolePermissions: TPermission[] = [`rc-closeevents`, `rc-comment`, `rc-createevents`, `rc-modifyevents`, `rc-tasks`, `rc-viewevents`, `rc-webnotifications`];

  private userLogged: boolean = false;
  private adfsUrl = environment.production ? environment.login.adfsUrl_production : environment.login.adfsUrl_certification;
  private resource_id = environment.login.resource_id; //RP ID (Identifier) of Web API in application group. e.g., https://localhost:16345
  private client_id = environment.login.client_id;
  private client_secret = environment.login.client_secret; //Secret of the web app (server application) in the application group.
  private application_redirect_url = environment.login.application_redirect_url
  private decodedToken: any = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private eventUtilityService: EventUtilityService,
    private permissionService: PermissionService
  ) {}

  checkAuthorizationStatus() {
    if (!this.isTokenValid()) {
      this.revokeAuthorization()
    }
  }

  setDeveloperMode(role?: TRole, permissions?: TPermission[]) {
    this.permissionService.assignPermission(role ? role : this.developerRole, permissions ? permissions : this.developerRolePermissions);
    const user = {
      userId: this.permissionService.getRole(),
      firstName: "Test",
      lastName: "Developer",
      fullName: "Test Developer",
      email: "developer.email@test.com",
      role: this.permissionService.getRole(),
    }
    this.setUpLocalStorage(user);
    this.eventUtilityService.setHasMultipleRoles(true)
    localStorage.setItem("hasMultipleRoles", "true");
    this.resetApiToken();
    this.setUserLogged(true);
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 1500);
  }

  setUserInfo() {
    const user = this.getUserInfo();
    this.setUpLocalStorage(user);
  }

  getUserInfo() {
    const user = {
      //userId: this.decodedToken.WindowsAccountName, //TODO: use this when BE will need it
      userId: this.extractRole(this.decodedToken.claims), //TODO: Remove when BE ready
      firstName: this.decodedToken.given_name,
      lastName: this.decodedToken.family_name,
      fullName: `${this.decodedToken.given_name} ${this.decodedToken.family_name}`,
      email: this.decodedToken.email,
      role: this.extractRole(this.decodedToken.claims)
    }
    if (localStorage.getItem("hasMultipleRoles") == "true") {
      this.eventUtilityService.setHasMultipleRoles(true)
    }

    return user;
  }

  setUpLocalStorage(user: ReturnType<typeof this.getUserInfo>) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userId", user.userId);
    localStorage.setItem("userFirstName", user.firstName);
    localStorage.setItem("userLastName", user.lastName);
    localStorage.setItem("userFullName", user.fullName);
    localStorage.setItem("userEmail", user.email);
    localStorage.setItem("userRole", user.role);
    localStorage.setItem("userPermissions", JSON.stringify(this.permissionService.getPermissions(this.permissionService.getRole())));
  }

  resetApiToken() {
    const url = environment.backend.baseURLToken + "v2/sdpr/oauth2/token";

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', environment.backend.tokenRefresh);
    headers = headers.append('Cookie', environment.backend.xsrfToken);
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('scope', 'integration.fcl.fcagcv.com/ThunderManagement');

    return this.http.post(url, body.toString(), {headers: headers, withCredentials: true}).subscribe({
      next: (res: any) => {
        if (res?.access_token && res?.token_type) {
          this.setApiToken(res.token_type + " " + res.access_token)
          if (environment.login.resource_id == "RC_EMEA_INT") {
            this.setAccessToken(res.token_type + " " + res.access_token)
          }
        }
        return of("INTERCEPTOR: We refreshed the token now do again what you were trying to do");
      },
      error: error => {
        console.error("INTERCEPTOR: Non Authentication Error", error)
      },
      complete: () => {
      }
    });
  }

  startAuthProcess() {
    //-------------------------------------------------------STEP 1: Verify token validity
    if (!this.isTokenValid()) {
      //-----------------------------------------------------STEP 2.1: 2.1 Redirect ADFS Login with parameters and 2.2 get "AuthorizationCode"
      this.redirectToADFSLogin();
      //STEP 2.2, STEP3, STEP4, and STEP5 of the Auth Process are dealth in the redirect page (login-callback) using the continueAuthProcess function in this service
    } else {
      this.authGranted(true);
    }
  }

  redirectToADFSLogin() {
    window.location.href = `${this.adfsUrl}/adfs/oauth2/authorize?response_type=${'code'}&resource=${this.resource_id}&client_id=${this.client_id}&redirect_uri=${this.application_redirect_url}`
  }

  authGranted(authorized: boolean) {
    if (authorized) {
      this.setUserInfo();
      this.setUserLogged(true);
      this.resetApiToken();
      this.router.navigate(['/home']);
    } else {
      this.revokeAuthorization();
    }
  }

  setUserLogged(value: boolean) {
    this.userLogged = value;
  }

  sanitizeForUrl(inputString: string) {
    return encodeURIComponent(inputString);
  }

  continueAuthProcess() {
    //-----------------------------------------------------STEP 2.2 get AuthorizationCode
    const redirect_url = window.location.href;
    const authorizationCode: string = this.extractAuthorizationCode(redirect_url);
    //-----------------------------------------------------STEP 3: Call ADFS API with "AuthorizationCode" to get JWT Token
    const step3params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', authorizationCode)
      .set('resource', this.resource_id)
      .set('client_id', this.client_id)
      .set('redirect_uri', this.application_redirect_url)
      .set('client_secret', this.client_secret);

    this.getJWToken(step3params).subscribe({
      next: response => {
        this.setRefreshToken(response.body.refresh_token);
        this.setAccessToken(response.body.access_token);
        //setTimeout(() => {this.setRefreshToken('')}, response.body.refresh_token_expires_in * 1000) //clears the token when expired
        //setTimeout(() => {this.setAccessToken(''); response.body.expires_in * 1000);
        //------------------------------------------------STEP 4: Validate JWT Token using ADFS public certificate
        if (this.isTokenValid()) {
          this.setUserInfo()
          this.authGranted(true)
        } else {
          this.authGranted(false);
        }
      },
      error: error => {
        console.error('getJWToken() Error:', error);
      }
    });
  }

  extractAuthorizationCode(url: string): string {
    const urlSearchParams = new URLSearchParams(url.split('?')[1]);
    if (urlSearchParams.get('code')) {
      return urlSearchParams.get('code')!
    } else {
      console.error("No authorization code provided")
      return ''
    }
  }

  getJWToken(bodyParams: HttpParams): Observable<HttpResponse<any>> {
    const step3Url = `${this.adfsUrl}/adfs/oauth2/token`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(step3Url, bodyParams.toString(), {
      headers,
      observe: 'response',
    })
  }

  //Token Validator: Checks the valitidy of the token
  isTokenValid(): boolean {
    //---------------------TESTING: REMOVE THIS SECTION WHEN GAP READY
    //this.setToken("eyJraWQiOiJBd09QQzd4Smk3MHpJVVNOWnZuOXpaOXNaemZwbUYzTFwvQncrcHRGSEpcL2s9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIzcDRkMGZta2pyN3JobWozZ2Vubjc2ZDltbCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiaW50ZWdyYXRpb24uZmNsLmZjYWdjdi5jb21cL1RodW5kZXJNYW5hZ2VtZW50IiwiYXV0aF90aW1lIjoxNzA5MTE1ODkxLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9OZUQ0MmlseHEiLCJleHAiOjE3MDkxMTk0OTEsImlhdCI6MTcwOTExNTg5MSwidmVyc2lvbiI6MiwianRpIjoiMzkyMGI1YjItZGYyMi00ZjU3LThiNzUtNjE0MWRhMmIxYWM5IiwiY2xpZW50X2lkIjoiM3A0ZDBmbWtqcjdyaG1qM2dlbm43NmQ5bWwifQ.gYRO6gaxzZwcfkXboqJBPLUonyUI4G4JHdWIPeDJ4pNpuv5cBndY6rhE4ysYnRa_7DaUdYq84ydf3deXxc8trbYpY7cYEuj4MUSgCYrNP-e6Vrhwbt57pcpKu4W1JxJBFKPKgfdS28k8WuGTNNfs3DQdcoGqe0z_Zm9N5DajOpzP3980Gt9g51AzibdumrT_TVNjQM2iTRgR0AHU_UjZh4zSlu7PgV1uTiPporYtR0g_o2V-QlMd9q1oEiBlzGWulJzbNpgwMX2g4knDdHAir5AgNPzKoP0ISITqvqw3lZc-9PlaYhYstemmvYs_iCbQmDMVyaLRoqDCG5b-eMlA-A");
    //-----------------------------------------------------------------
    if (this.readAccessToken() != "" && this.isTokenExpired(this.readAccessToken())) {
      if (this.readRefreshToken() != "" && !this.isTokenExpired(this.readRefreshToken())) {
        this.refreshToken(this.readRefreshToken());
      }
    }

    return !!(this.readAccessToken() &&
      !this.isTokenExpired(this.readAccessToken()) &&
      this.isTokenADFSValidated(this.readAccessToken()));
  }

  isTokenExpired(token: any): boolean {
    this.decodedToken = this.decodeJwt(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return !(this.decodedToken && this.decodedToken?.exp && typeof this.decodedToken?.exp == 'number' && this.decodedToken?.exp > currentTime);
  }

  decodeJwt(token: string): any {
    try {
      return jwtDecode(token)
    } catch (error) {
      console.error("Error decoding adfs token:", error);
    }
  }

  //TODO: validation logic using ADFS public certificate
  isTokenADFSValidated(token: string): boolean {
    return true;
  }

  extractRole(claims: any) {
    const parsedClaims = JSON.parse(claims)
    const applications = parsedClaims.applications;

    applications.forEach((application: any) => {
      application.regions.forEach((region: any) => {
        this.permissionService.assignPermission(region.role, region.permissions);
      })
    })
    return this.permissionService.getRole();
  }


  refreshToken(refresh_token: string) {
    const step3params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('resource', this.resource_id)
      .set('client_id', this.client_id)
      .set('refresh_token', refresh_token)
      .set('client_secret', this.client_secret);

    this.getJWToken(step3params).subscribe({
      next: response => {
        this.setAccessToken(response.body.access_token);
        //STEP 4: Validate JWT Token using ADFS public certificate
        if (this.isTokenValid()) {
          //STEP 5: Verify app name exists within the ADFS "claim" section
          if (this.appExists()) {
            this.authGranted(true);
          } else this.authGranted(false);
        } else {
          this.authGranted(false);
        }
      },
      error: error => {
        console.error('getJWToken() within refreshToken() Error:', error);
      }
    });
  }

  //TODO: Verify app name exists within the ADFS "claim" section
  appExists(): boolean {
    //get app name list
    return true;
  }

  /*
   * Token Setter
   * @param {string} token  Token to store
   */

  setAccessToken(token: string | undefined) {
    this.storeToken(token);
  }

  setRefreshToken(refreshToken: string | undefined) {
    this.storeRefreshToken(refreshToken);
  }

  setApiToken(token: string | undefined) {
    this.storeApiToken(token);
  }

  /*
   * Token Getter
   * @returns Token stored in controller
   */

  getAccessToken(): string {
    return this.readAccessToken();
  }

  getRefreshToken(): string {
    return this.readRefreshToken();
  }

  getApiToken(): string {
    return this.readApiToken();
  }

  /*
   * Method that check if the Token is still valid for the current session
   * @returns status of the token (true == Logged, false == Not Logged)
   */

  isLogged(): boolean {
    this.checkAuthorizationStatus()
    return this.userLogged
  }

  /*
   * Method that get the Token in the Local Storage
   * @returns Token stored in Local Storage
   */

  protected readAccessToken(): string {
    return localStorage.getItem('accessToken')?.toString() != undefined ? localStorage.getItem('accessToken')!.toString() : "";
  }

  protected readRefreshToken(): string {
    return localStorage.getItem('refreshToken')?.toString() != undefined ? localStorage.getItem('refreshToken')!.toString() : "";
  }

  protected readApiToken(): string {
    return localStorage.getItem('apiToken')?.toString() != undefined ? localStorage.getItem('apiToken')!.toString() : "";
  }


  /*
   * Method that set the Token in the Local Storage
   * @param {string} token  Token to store
   */

  protected storeToken(token: string | undefined) {
    localStorage.setItem('accessToken', token ? token : '');
  }

  protected storeRefreshToken(refreshToken: string | undefined) {
    localStorage.setItem('refreshToken', refreshToken ? refreshToken : '');
  }

  protected storeApiToken(token: string | undefined) {
    localStorage.setItem('apiToken', token ? token : '');
  }

  revokeAuthorization() {
    this.setAccessToken(undefined);
    this.setRefreshToken(undefined);
    this.setApiToken(undefined);
    this.setUserLogged(false);
    this.router.navigate(['/landingPage']);
    localStorage.clear();
    sessionStorage.clear();
    this.eventUtilityService.cleanUtilityService();
  }
}
