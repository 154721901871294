import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';


/**
 * Authentication Guard
 */


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router, ){

  };
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const eventId = route.queryParamMap.get('eventId');
      if (eventId) localStorage.setItem("eventId", eventId);
      if(this.authService.isLogged()){
        return true;
      }
    //return this.router.navigate(['/login']);
    return this.router.navigate(['/landingPage']);
  }

}
