import { Component } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {PermissionService} from "../../services/permission.service";
import {TPermission, TRole} from "../../types/perimission.type";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  redirecting: boolean = true;

  constructor(private authService: AuthService, private router: Router, private permissionService: PermissionService) {
    if(environment.login.resource_id === "RC_EMEA_INT") {
      if (!localStorage.getItem('accessToken')) {
        this.router.navigate(['/login']);
      } else {
        const role: TRole = localStorage.getItem('userRole') as TRole;
        const permissions: TPermission[] = JSON.parse(localStorage.getItem('userPermissions') || "[]");
        this.authService.setDeveloperMode(role, permissions);
      }
    } else {
      this.redirecting = false;
    }
  }

  onLoginRequest() {
    this.redirecting = true
    this.authService.startAuthProcess();
  }
}
