<div class="h-100 d-flex bgLogin">
  <div class="loginContainer">
    <div class="d-flex">
      <div
        style="margin-left: 45px"
        class="d-flex flex-column align-items-center pt-3"
      >
        <div style="margin-top: 48px">
          <img
            class="logo"
            [src]="'assets/images/logoStellantisBig.svg'"
            alt="logo"
          />
        </div>
        <div class="logoSeparator"></div>
        <div style="margin-top: 16px">
          <span class="rcLabelBold">RC </span>
          <span class="rcLabel">Portal</span>
        </div>
        <div
          class="d-flex flex-column align-items-center"
          style="margin-top: 37px"
        >
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div style="margin-bottom: 19px">
              <label class="loginLabel" for="first-name">Username </label>

              <input
                class="inputLogin"
                id="first-name"
                type="text"
                formControlName="username"
                tabindex="1"
              />

              <span
                class="text-danger"
                *ngIf="
                  (!loginForm.get('username')?.valid &&
                  loginForm.get('username')?.touched) ||
                  unauthorizedWarning
                "
                >Wrong or missing username</span
              >
            </div>

            <div style="margin-bottom: 19px">
              <label class="loginLabel" for="last-name">Password </label>

              <div class="containerPwd">
                <input
                  class="inputLogin inputLoginPwd"
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  tabindex="2"
                />
                <div (click)="togglePwd()" class="eyeBtn">
                  <img
                    [src]="'assets/icons/eye.svg'"
                    alt="show/hide password"
                  />
                </div>
              </div>

              <span
                class="text-danger"
                *ngIf="
                  !loginForm.get('password')?.valid &&
                  loginForm.get('password')?.touched
                "
                >Wrong or missing password</span
              >
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <input class="old-checkbox" id="remember-me" type="checkbox" />
                <div class="custom-checkbox my-auto"></div>
                <label
                  style="margin-left: 30px"
                  class="checkboxLabel"
                  for="remember-me"
                  >Remember me</label
                >
              </div>
              <div class="information informationLink">Forgot password</div>
            </div>
            <div style="margin-top: 42px">
              <button
                class="btnLogin"
                type="submit"
                [disabled]="loginForm.invalid"
                tabindex="3"
              >
                <span class="loginBtnLabel"> Log in </span>
              </button>
            </div>
          </form>
          <div style="margin-top: 56px; margin-right: auto">
            <div class="information">Don't have an account ?</div>
            <div class="information informationLink">
              Request login information
            </div>
          </div>
        </div>
      </div>
      <div class="imgContainer">
        <img [src]="'assets/images/loginImage.png'" alt="login image" />
      </div>
    </div>
  </div>
</div>
