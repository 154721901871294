import { Component } from '@angular/core';
import { AuthService } from 'src/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  redirecting: boolean = true;

  constructor(private authService: AuthService, private router: Router,) {
    if(environment.login.resource_id === "RC_EMEA_INT") {
      if (!localStorage.getItem("userRole")) {
        this.router.navigate(['/login']);
      } else {
        this.authService.setDeveloperMode(localStorage.getItem("userRole")!);
      }
    } else {
      this.redirecting = false;
    }
  }

  onLoginRequest() {
    this.redirecting = true
    this.authService.startAuthProcess();
  }
}
