import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from '../views/login/login.component';
import { LoginCallbackComponent } from 'src/views/login-callback/login-callback.component';
import { LandingPageComponent } from 'src/views/landing-page/landing-page.component';
import { RoleSelectionComponent } from 'src/views/role-selection/role-selection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from 'src/services/auth/auth.service';
import { AuthGuard } from 'src/guards/auth.guard';
import { PageNotFoundComponent } from 'src/views/page-not-found/page-not-found.component';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from 'src/shared-components/map-viewer/services/interceptor';
import { CachingInterceptor } from 'src/services/road-clearence/caching.interceptor';

@NgModule({
  declarations: [AppComponent, LoginComponent, LoginCallbackComponent, LandingPageComponent, RoleSelectionComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthService, 
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CachingInterceptor,
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
