import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth/auth.service';
import { interval } from 'rxjs';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'stellantis-thunder';
  loading$: Observable<boolean>;


  constructor(private authService: AuthService, private router: Router, private loadingService:LoadingService) {
    this.loading$ = this.loadingService.isLoading;
  }

  ngOnInit() {
    //remove after development complete
    //environment.login.resource_id == "RC_EMEA_INT" ? this.authService.setDeveloperMode() : ()=>{};
    interval(120 * 1000).subscribe(() => {
      this.authService.checkAuthorizationStatus()
    })
  }
}
