import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, of, throwError, finalize } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "src/services/auth/auth.service";
import { LoadingService } from "src/services/loading.service";


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor{

  constructor(private injector: Injector, private router: Router, private loadingService:LoadingService){
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //TODO: use the interceptor to trigger loaders
    this.loadingService.showLoader();
    return next.handle(req).pipe(
      catchError(x=> this.handleErrors(x)),
      finalize(() => {
        //change this with 
        this.loadingService.hideLoader();
      }) 
    );
  }

  private handleErrors(err: HttpErrorResponse): Observable<any> {
    if(err instanceof HttpErrorResponse) {
      const authService = this.injector.get(AuthService) //this is needed because defining it in the constructor gives a circular dependency error
      //TODO: currently, the server sends errors with status 0 and they are all recognised as Unknown HTTP Error. Needs to change server config
      switch (err.status) {
        case 401:
          authService.revokeAuthorization()
          return of("INTERCEPTOR: Attempting to Refresh Tokens");
        case 504:
          return throwError(() => new Error("INTERCEPTOR: Gateway Timeout Error"));
        default:
          return throwError(() => new Error("INTERCEPTOR: Unknown HTTP Error"));
      }
    }
    else {
      //console.error("Non-HTTP error intercepted:", err);
      return throwError(() => new Error("INTERCEPTOR: Unknown Error"));
    } 
  }
}