import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../views/login/login.component';
import { AuthGuard } from 'src/guards/auth.guard';
import { PageNotFoundComponent } from 'src/views/page-not-found/page-not-found.component';
import { LoginCallbackComponent } from 'src/views/login-callback/login-callback.component';
import { LandingPageComponent } from 'src/views/landing-page/landing-page.component';
import { RoleSelectionComponent } from 'src/views/role-selection/role-selection.component';

const routes: Routes = [
  { path: 'landingPage', component: LandingPageComponent},
  { path: 'loginCallback', component: LoginCallbackComponent},
  { path: 'login',component: LoginComponent},
  {
    path: '',
    canActivate:[AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('../views/mapbox/mapbox.module').then((m) => m.MapboxModule),
      },
      {
        path: 'roleSelection',
        component: RoleSelectionComponent
      },
      {
        path: '',
        pathMatch:'full',
        redirectTo:'home'
      }, 
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
