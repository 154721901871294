import {Injectable} from '@angular/core';
import {TPermission, TRole} from "../types/perimission.type";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _userRole: Set<TRole> = new Set<TRole>();
  private _rolePermissions!: Record<TRole, Set<TPermission>>;

  assignPermission(role: TRole, permission: TPermission[]) {
    this._userRole.add(role);
    this._rolePermissions = {
      ...this._rolePermissions,
      [role]: new Set(permission)
    }
  }

  hasPermission(role: TRole, permission: TPermission): boolean {
    const permissions: Set<TPermission> | undefined = this._rolePermissions[role];
    return permissions ? permissions.has(permission) : false;
  }

  hasRole(role: TRole): boolean {
    return this._userRole.has(role);
  }

  getPermissions(role: TRole): TPermission[] {
    return Array.from<TPermission>(this._rolePermissions[role]);
  }

  getRole(): TRole {
    return this._userRole.values().next().value as TRole;
  }

  removeRole(role: TRole) {
    this._userRole.delete(role);
    delete this._rolePermissions[role];
  }
}
